$barnardos-brand-colour: hsl(81, 100%, 32%);

.barnardos {
  svg {
    fill: $barnardos-brand-colour;
    width: 100%;
    height: 25px;
  }
  .wider {
    width: 300px;
  }
  .headline {
    @extend %flex-layout;
    font-size: 2em;
  }
  .reporting-type {
    font-size: 0.4em;
    display: inline-block;
    max-width: 5em;
    font-style: italic;
  }
  .timeline {
    @extend %flex-layout;
    > :not(:first-child) {
      margin-left: 1em;
    }
    > button {
      width: 5em;
    }
    > input {
      flex-grow: 2;
    }
    > div {
      font-style: italic;
      font-size: 0.8em;
      display: flex;
      align-items: center;
    }
  }
  @include desktop {
    .two-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        width: 49%;
      }
    }
  }
}