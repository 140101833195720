.mumsnet {
  max-width: 100%;
  svg {
    max-width: 2.5em;
    circle {
      fill: $mumsnet-brand-colour;
    }
  }
  .selected {
    background-color: change-color($mumsnet-brand-colour, $lightness: 75%);
  }
  table {
    border-collapse: collapse;
    overflow: hidden;
    display: block;
    @include desktop {
      font-size: 2em;
    }
    td,
    th {
      padding: 0.2rem 0.5rem;
      text-align: center;
    }
    th {
      font-weight: bold;
    }
    .term {
      width: 7rem;
      font-style: italic;
      text-transform: capitalize;
      text-align: left;
    }
  }
}
