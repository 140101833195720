$popover-size: 80%;

$background-colour: white;
$mumsnet-brand-colour: hsl(204, 100%, 32%);
$teacher-tapp-brand-colour: hsl(164, 76%, 47%);

@import 'reset', 'odileeds';

// Mixins
@mixin desktop() {
  @media only screen and (min-width: 720px) {
    @content;
  }
}

// Placeholders
%standard-font {
  font-family: $odileeds-font-stack;
  font-size: $odileeds-font-size;
  line-height: 1.2;
}

%spaced-text {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

%content-block {
  padding-top: 60px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 2em;
}

%full-size {
  width: 100%;
  height: 100%;
}

%flex-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


h1 {
  @extend %spaced-text;
  font-size: 1.5em;
}

h2 {
  @extend %spaced-text;
  font-size: 1.2em;
  font-weight: bold;
}

h3 {
  @extend %spaced-text;
  font-size: 1.1em;
  font-weight: bold;
}

html {
  @extend %full-size;
}

input,
select,
button {
  @extend %standard-font;
}

td {
  vertical-align: middle;
}

body {
  @extend %standard-font;
  @extend %full-size;
  > header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    background: $odileeds-colour-c10;
    box-shadow: 0 0 5px 1px hsla(0, 0%, 65%, 95%);
    padding: 1rem;
    z-index: 100;
    h1 {
      padding: 0;
      margin: 0;
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
  > aside {
    @extend %content-block;
    @extend %full-size;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: $background-colour;
    z-index: 50;
    display: none;
    overflow-y: scroll;
    &.open {
      display: initial;
    }
  }
  @include desktop {
    > aside {
      display: initial;
      width: 18rem;
      section {
        &.hidden {
          :not(:first-child) {
            display: none;
          }
        }
      }
    }
  }
}

.grey-button {
  color: white;
  background: hsla(0, 0, 30%, 0.5);
  border: none;
  border-radius: 2em;
  padding: 0.5em;
}

.toc-display {
  @extend .grey-button;
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 1000;
  width: 7em;
  @include desktop {
    display: none;
  }
}

[role='main'] {
  @extend %content-block;
  @include desktop {
    margin-left: 22rem;
    max-width: 50em;
  }
}

@import 'barnardos',
        'mumsnet';

.teachertapp {
  .selector {
    @extend %flex-layout;
    margin: 0.5em 0;
    height: 2em;
    svg {
      padding: 0 0.5em;
    }
    select {
      flex-grow: 2;
    }
  }
  label {
    display: block;
    width: 10em;
  }
  .grid {
    @extend %flex-layout;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
  }
  .response {
    width: 100px;
    padding-bottom: 1em;
    margin-right: 0.5em;
    p {
      font-size: 0.8em;
      margin: 0;
    }
  }
  svg {
    .filled {
      fill: $teacher-tapp-brand-colour;
      stroke: $teacher-tapp-brand-colour;
    }
    .baseline {
      fill: adjust-hue($teacher-tapp-brand-colour, 120deg);
    }
  }
}
